<template>
  <v-form>
    <form-subheader title="Devices info" class="pt-4" />
    <v-select
      v-model="objectType"
      :items="drivers"
      item-text="name"
      item-value="id"
      label="Device type"
      @input="sendData"
    />
    <v-select
      v-model="status"
      :items="['Enabled', 'Disabled', 'All']"
      item-text="name"
      item-value="id"
      label="Status"
      @input="sendData"
    />
    <v-text-field
      v-model="objectNameContains"
      label="Name contains"
      @input="sendData"
    />
    <form-subheader title="Objects info" class="pt-4" @input="sendData" />
    <v-select
      v-model="type"
      :items="types"
      :loading="false"
      item-text="name"
      item-value="id"
      label="Type"
      @input="sendData"
    />
    <v-text-field
      v-model="objectNameTemplate"
      label="Object name template"
      @input="sendData"
    />
    <v-text-field
      v-model="objectTemplateStart"
      label="Start numbering with"
      @input="sendData"
    />
    <select-chips
      v-model="objectGroups"
      multiple
      :items="groups"
      label="Object groups"
      item-text="name"
      item-value="id"
      chips
      class="ma-0"
      @input="sendData"
      @drop-item="handleDropChip(objectGroups, $event, 'id')"
    />
  </v-form>
</template>

<script>
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { useLazyQuery, useResult } from '@vue/apollo-composable';

import SelectChips from '@/components/_base/SelectChips';

import schemas from '@/modules/objects/api/object-from-devices.graphql';
import schemaTypes from '@/modules/objects/api/types.graphql';

export default {
  name: 'GeneralTab',
  components: {
    SelectChips
  },
  props: {
    data: { type: Object, default: () => ({}) }
  },
  setup(_, { emit }) {
    const state = reactive({
      objectType: '',
      status: 'All',
      type: '',
      objectNameContains: '',
      objectNameTemplate: 'Object {:d}',
      objectTemplateStart: 1,
      objectGroups: []
    });

    const driversQuery = useLazyQuery(
      schemas.fetchDrivers,
      {},
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const typesQuery = useLazyQuery(
      schemaTypes.fetchTypes,
      {
        filter: {
          mTags: { contains: ['application', 'monitor', 'object'] }
        }
      },
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const fetchGroups = useLazyQuery(
      schemas.fetchObjectGroups,
      {},
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const sendData = () => {
      emit('update-form', { key: 'general', data: state });
    };

    const handleDropChip = (array, id, typeOperation) => {
      if (typeOperation === 'id') {
        array.splice(
          array.findIndex(i => i.id === id),
          1
        );
      } else {
        array.splice(
          array.findIndex(i => i === id),
          1
        );
      }
    };

    const drivers = useResult(driversQuery.result, [], data => data.schemata);
    const types = useResult(typesQuery.result, [], data => data.schemata);
    const groups = useResult(fetchGroups.result, [], data => data.objects);

    onMounted(() => {
      driversQuery.load();
      typesQuery.load();
      fetchGroups.load();
    });

    return {
      ...toRefs(state),
      drivers,
      types,
      groups,
      handleDropChip,
      sendData
    };
  }
};
</script>
